import React, { useEffect, useRef, useState } from 'react';
import './Accounting.css';
import { card_list_csv, create_card_checkpoint, create_checkpoint, get_all_members, get_all_stocks, get_checkpoints, get_current_cards, load_movements, newStockTransaction, update_member } from '../../../utils/talent_services';
import { useNavigate } from 'react-router-dom';
import Modal_proyectos from '../../menus/Modal_proyectos_brevo';
import Select from "react-select";


const Stocks = ({usr_id}) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [Holderslist, setHolderslist] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedOrigin, setselectedOrigin] = useState(0);
  const [selectedDestiny, setselectedDestiny] = useState(0);
    const [members, setMembers] = useState([]);
  const [newAmount, setnewAmount] = useState(0);
  const [TotalShares, setTotalShares] = useState(0);
  const [newComments, setnewComments] = useState('');
  const [file, setFile] = useState(null);

  const refresh_members_list = () => {
    async function fetchCheckpoints() {
        const membersList = await get_all_members(token);
        if (membersList==="INVALID"){
          navigate("/");
        }else{

          const members = membersList.map((element, index) => {
            return {id:element.id, trello_id: element.member_id, value: element.id, label: element.nombre, email:element.email, costo: element.costo, index: index };
          });

          setMembers(members);
                  
       }
      }
      fetchCheckpoints();
  }

  const refresh_stocks_list = () => {
    async function fetchStocks() {
        const holdersListget = await get_all_stocks(token);
        if (holdersListget==="INVALID"){
          navigate("/");
        }else{

          let total_shares =0;
          const holdesLoad = holdersListget.map((element, index) => {
            total_shares +=Number(element.amount);
            return {id:element.id, value:element.holder, holder: element.holder, label: element.Nombre, num_transactions: element.num_transactions, amount: element.amount, index: index };
          });
          
          setTotalShares (total_shares);
          setHolderslist(holdesLoad);
                  
       }
      }
      fetchStocks();
  }

  
  const handleFileChange = (e) => {

    setFile ( e.target.files[0]);
  //console.log('Selected file:', file);

};

  
  useEffect(() => {
    refresh_stocks_list();
    refresh_members_list();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  const saveTransaction = () =>{

    async function fetchAddTransaction() {
      console.log("Origin selected: " + selectedOrigin);
      const packageslist = await newStockTransaction(token,selectedOrigin , selectedDestiny,file,newAmount.replace(/\./g, "").replace(/\D/g, ""),newComments);
      if (packageslist==="INVALID"){
        navigate("/");
      }else{
        refresh_stocks_list();
        setModalOpen(false);
      }
    }

    if (file === ""  || newAmount === "" || selectedOrigin === "" || selectedDestiny === ""){
      alert("Debe completar todos los datos");
      return;
    }

    if (window.confirm("¿Está seguro de querer registar el movimiento de " + newAmount + " acciones?" )) {
      fetchAddTransaction();
    }
      
  }

  const formatCosto = (costo) => {

     // Eliminar puntos previos y todo lo que no sea número
     const numericValue = costo.replace(/\./g, "").replace(/\D/g, "");
     if (!numericValue) return ""; // Evita mostrar "NaN" si está vacío
 
     // Formatear con separadores de miles
     return parseInt(numericValue, 10).toLocaleString("es-ES");
  };

  const newEntryModal = () => {

    setModalOpen(true);
  };
  const handleChange = (e) => {
    const formattedValue = formatCosto(e.target.value);
    setnewAmount(formattedValue);
  };

  return (
 
    <div className="containerChk">
      <Modal_proyectos isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <h3>Nuevo Movimiento de Stocks</h3>
        <div>
        Origen:
        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={Holderslist}
                            onChange={(selectedOption) => setselectedOrigin(selectedOption.value)}
                            placeholder="Buscar producto..."
                            isSearchable
                          />
        </div>
        Destino:
              <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={members}
                            onChange={(selectedOption) => setselectedDestiny(selectedOption.value)}
                            placeholder="Buscar producto..."
                            isSearchable
                          />
       
       Cantidad:
        <input
          type="text"
          id="fecha"
          value={newAmount}
          onChange={handleChange}
        />

        Soporte:
        <input type="file" onChange={handleFileChange} className="file-input" accept="Documento PDF, .pdf" />
        <div>
        Obervaciones:
        <textarea
                              id="serial"
                              rows="5"
                              cols="50"
                              onChange={(e) => setnewComments(e.target.value)}
                              value={newComments}
                            />


        <button onClick={() => saveTransaction()} className="add-button">Guardar</button>
        </div>
      </Modal_proyectos>

        <div className='cards-list'>
        <h3>Total Acciones:  {formatCosto(String(TotalShares))}  </h3>
   
        <table>
          <thead>
            <tr>
              <th style={{ width: '50%' }}>holderName</th>
              <th style={{ width: '20%', textAlign: 'right'  }}>Transactions</th>
              <th style={{ width: '20%', textAlign: 'right'  }}>Amount</th>
              
            </tr>
          </thead>
          <tbody>
            {Holderslist.map((member, index) => {

                    return (
                        <tr
                          key={index}
                        >
                        <td><a href={"/Individual_stock?holder_id=" +member.holder}>{member.label}</a> </td>
                        <td style={{ textAlign: 'right' }}>{member.num_transactions}</td>
                        <td style={{ textAlign: 'right' }}>{formatCosto(member.amount)}</td>
                       
                        </tr>
                      )
          
              })}
          </tbody>
         
        </table>
            <button className="floating-button" onClick={() => newEntryModal()}>
            +
          </button>
        </div>
    </div>
      
   
  );
};

export default Stocks;
