const baseURL = 'https://team.tomi.la/server/talent/';

export async function create_checkpoint (token, usr_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('usr_id', usr_id);
    let result = await loadJson (data, 'create_checkpoint');
    return result[0];
}



export async function update_dashboard (token) {
    const data = new FormData();
    data.append('token', token);
    let result = await loadJson (data, 'update_dashboard');
    return result[0];
}


export async function card_list_csv (token) {
    const data = new FormData();
    data.append('token', token);
    let result = await loadCSV (data, 'card_list_csv');
    return result;
}


export async function update_member (token, memberId, newCosto) {
    const data = new FormData();
    data.append('token', token);
    data.append('memberId', memberId);
    data.append('newCosto', newCosto);
    let result = await loadJson (data, 'update_member');
    return result[0];
}


export async function load_movements (token, movements) {
    const data = new FormData();
    data.append('token', token);
    data.append('movements', JSON.stringify(movements));
    let result = await loadJson (data, 'load_movements');
    return result[0];
}

export async function create_card_checkpoint (token, thecard, checkpoint_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('thecard', JSON.stringify(thecard));
    data.append('checkpoint_id', checkpoint_id);
    let result = await loadJson (data, 'create_card_checkpoint');
    return result[0];
}

export async function create_contributions (token, members_rate, card_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('card_id', card_id);
    data.append('members_rate', JSON.stringify(members_rate));
    let result = await loadJson (data, 'create_contributions');
    return result[0];
}

export async function set_dedication (token, cards) {
    const data = new FormData();
    data.append('token', token);
    data.append('thecard', JSON.stringify(cards));
    let result = await loadJson (data, 'set_dedication');
    return result[0];
}

export async function save_votes (token,checkPoint_id, cards) {
    const data = new FormData();
    data.append('token', token);
    data.append('checkPoint_id', checkPoint_id);
    data.append('thecard', JSON.stringify(cards));
    let result = await loadJson (data, 'save_votes');
    return result[0];
}



export async function get_checkpoints (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_checkpoints');
}

export async function save_user_learning (token, annotation,newLink, checkpoint) {
    const data = new FormData();
    data.append('token', token);
    data.append('annotation', annotation);
    data.append('newLink', newLink);
    data.append('checkpoint', checkpoint);
    return await loadJson (data, 'save_user_learning');
}

/**
 * 
 * @param {*} numElementos numero de elemento sobre el que se mide el peso
 * @param {*} posicion  la posicion del elemento
 * @param {*} base es la base porcentual sobre la que se calcula el el peso
 * @returns 
 */
export function peso(numElementos, posicion, base) {
    // Calcular la suma de los pesos inversos
    let sumaPesos = 0;
    for (let i = 1; i <= numElementos; i++) {
        sumaPesos += 1 / i;
      
    }
    // Calcular el peso del elemento en la posición dada
    let pesoElemento = 1 / posicion;
    // Calcular el porcentaje del elemento normalizando su peso
    let porcentaje = (pesoElemento / sumaPesos) * base;
    return porcentaje.toFixed(2); // Devolver el porcentaje con dos decimales
}


export 
function get_base_url () {
   
    return baseURL;
}

export async function get_all_stocks (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_all_stocks');
}

export async function get_member_stocks (token, member_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('member_id', member_id);
    return await loadJson (data, 'get_member_stocks');
}


export async function delete_stock_document (token, comprobante2delete) {
    const data = new FormData();
    data.append('token', token);
    data.append('comprobante2delete', comprobante2delete);
    return await loadJson (data, 'delete_stock_document');
}



export async function get_all_members (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_all_members');
}


export async function newStockTransaction (token, source_id, destination_id, file, amount, comments) {
    const data = new FormData();
    data.append('token', token);
    data.append('source_id', source_id);
    data.append('file', file);
    data.append('destination_id', destination_id);

    data.append('amount', amount);
    data.append('comments', comments);

    return await loadJson (data, 'newStockTransaction');
}

export async function get_current_cards (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_current_cards');
}




export async function get_current_sprint_cards (token, checkpoint_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('checkpoint_id', checkpoint_id);
    return await loadJson (data, 'get_current_sprint_cards');
}



export async function get_member_cards (token, checkpoint_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('checkpoint_id', checkpoint_id);
    return await loadJson (data, 'get_member_cards');
}


export async function get_member_selected_cards (token, checkpoint_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('checkpoint_id', checkpoint_id);
    return await loadJson (data, 'get_member_selected_cards');
}

/**
 * 
 * @param {*} token autenticacion del usuario que hace la peticion
 * @param {*} checkpoint_id checkpoing id del que quiero los miembros
 * @param {*} card_id id de trello de la tarjeta
 * @returns Lista de miembros de la tarjeta en el check point seleccionado
 */

export async function get_members_cp_card (token, checkpoint_id, card_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('checkpoint_id', checkpoint_id);
    data.append('card_id', card_id);
    return await loadJson (data, 'get_members_cp_card');
}

export const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};



async function loadCSV(data, url) {
    try {
        console.log(baseURL + url + ".php");

        const response = await fetch(baseURL + url + ".php", {
            method: 'POST',
            body: data
        });

        if (!response.ok) {
            throw new Error("Error en la respuesta del servidor");
        }

        // Convertir la respuesta en un blob (archivo)
        const blob = await response.blob();

        // Crear una URL para el blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Crear un enlace y simular clic para descargar
        const a = document.createElement("a");
        a.href = blobUrl;
        const today = new Date();
        const formattedDate = `${today.getDate().toString().padStart(2, '0')}_${
            (today.getMonth() + 1).toString().padStart(2, '0')}_${today.getFullYear().toString().slice(-2)}`;

            a.download = `all_cards_${formattedDate}.csv`; // Nombre del archivo con fecha
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Liberar el blob
        window.URL.revokeObjectURL(blobUrl);

    } catch (error) {
        console.error("Error:", error);
    }
}

async function loadJson (data, url) {

    try {
        console.log(baseURL + url + ".php");
        const response = await fetch(baseURL + url + ".php", {
            method: 'POST',
            body: data
        });
        const resp = await response.text();
        console.log(url + " plain answer:" + resp);
        if(resp !== "INVALID"){
            try{
                const jsonResult = JSON.parse(resp);
                return jsonResult;
            }catch(err){
                return resp;
            }
            
        }else{
            return "INVALID";
        }
       
    } catch (error) {
        console.error('Error:' + error);
        return null;
    }
}