const baseURL = 'https://team.tomi.la/server/';

export async function getProfile (token) {
    const data = new FormData();
    data.append('token', token);
    let result = await loadJson (data, 'user_permission');
    return result;
}

export async function save_new_issue (token, theSerial, newAnnotationText, newType, usr_id, canal, contacto, estado) {
    const data = new FormData();
    data.append('token', token);
    data.append('serial', theSerial);
    data.append('newAnnotationText', newAnnotationText);
    data.append('newType', newType);
    data.append('usr_id', usr_id);
    data.append('canal', canal);
    data.append('contacto', contacto);
    data.append('estado', estado);
    return await loadJson (data, 'save_new_issue');
}

export async function update_issue (token, issue_id, newAnnotationText, newType, canal, contacto, estado) {
    const data = new FormData();
    data.append('token', token);
    data.append('issue_id', issue_id);
    data.append('newAnnotationText', newAnnotationText);
    data.append('newType', newType);
    data.append('canal', canal);
    data.append('contacto', contacto);
    data.append('estado', estado);
    return await loadJson (data, 'update_issue');
}

export async function save_new_model_issue (token, model_ref, newAnnotationText, newType, usr_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('model_ref', model_ref);
    data.append('newAnnotationText', newAnnotationText);
    data.append('newType', newType);
    data.append('usr_id', usr_id);
    return await loadJson (data, 'save_new_model_issue');
}

export async function close_session (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'close_session');
}


export async function update_status (token, theSerial, status_type, fecha_warranty) {
    console.log ("Seriales a afectar: " + theSerial);
    const data = new FormData();
    data.append('token', token);
    data.append('serial', theSerial);
    data.append('status_type', status_type);
    data.append('fecha_warranty', fecha_warranty);
    return await loadJson (data, 'update_status');
}


export async function add_serial (token, theSerial, sub_cat, project_id) {
    console.log ("Seriales a afectar: " + theSerial);
    const data = new FormData();
    data.append('token', token);
    data.append('serial', theSerial);
    data.append('project_id', project_id);
    data.append('sub_cat', sub_cat);
    return await loadJson (data, 'add_serial');
}



export async function delete_projects_relation (token, id_to_delete) {
    const data = new FormData();
    data.append('token', token);
    data.append('id', id_to_delete);
    return await loadJson (data, 'delete_projects_relation');
}

export async function update_project(token, id, nombre_proy, link_token, link_id, link_name, country) {
    const data = new FormData();
    data.append('token', token);
    data.append('id', id);
    data.append('nombre_proy', nombre_proy);
    data.append('link_token', link_token);
    data.append('link_id', link_id);
    data.append('link_name', link_name);
    data.append('country', country);
    return await loadJson (data, 'update_project');
}


export async function get_models (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_models');
}

export async function  start_sesion (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'start_sesion');
}

export async function getPackages (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_packages');
}


export async function get_package_versions (token, package_name, current_version) {
    const data = new FormData();
    data.append('token', token);
    data.append('package_name', package_name);
    data.append('current_version', current_version);

    return await loadJson (data, 'get_package_versions');
}


export async function deploy_update (token, selectedProject, selectedCapacity, selectedModel, version2deploy, selectedPackagename) {
    const data = new FormData();
    data.append('token', token);
    data.append('selectedProject', selectedProject);
    data.append('selectedCapacity', selectedCapacity);
    data.append('selectedModel', selectedModel);
    data.append('version2deploy', version2deploy);
    data.append('selectedPackagename', selectedPackagename);

    return await loadJson (data, 'deploy_update');
}

export async function create_new_version (token, package_name, file, comments, versionCode, versionName,  fileEN,fileES,fileAR,filePT, fileBannerVid) {
    const data = new FormData();
    data.append('token', token);
    data.append('package_name', package_name);
    data.append('file', file);
    data.append('comments', comments);
    data.append('versionCode', versionCode);
    data.append('versionName', versionName);
    data.append('fileEN', fileEN);
    data.append('fileES', fileES);
    data.append('filePT', filePT);
    data.append('fileAR', fileAR);
    data.append('fileBannerVid', fileBannerVid);

    console.log(token, package_name, file, comments, versionCode, versionName,  fileEN,fileES,fileAR,filePT, fileBannerVid);

    return await loadJson (data, 'create_new_version');
}

export async function loadIssueType(token){
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_issue_types');
}



export async function add_project(token, projectName){
    const data = new FormData();
    data.append('token', token);
    data.append('projectName', projectName);
    return await loadJson (data, 'add_project');
}

export async function get_projects(token){
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_projects');
}

export async function request_brevo_csv (token, listid, link_token) {
    const data = new FormData();
    data.append('token', token);
    data.append('listid', listid);
    data.append('link_token', link_token);
    return await loadJson (data, 'brevo/request_brevo_csv');
}

export async function validate_procces (proc_id) {
    const data = new FormData();
    data.append('proc_id', proc_id);
    return await loadJson (data, 'brevo/validate_procces');
}
export async function get_project_permissions (token, project_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('project_id', project_id);
    return await loadJson (data, 'get_project_permissions');
}

export async function get_brevo_lists(token, folderid){
    const data = new FormData();
    data.append('token', token);
    data.append('folderid', folderid);
    return await loadJson (data, 'brevo/get_brevo_lists');
}

export async function get_brevo_folders(token){
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'brevo/get_brevo_folders');
}

export async function get_project_serials(token, id){
    const data = new FormData();
    data.append('token', token);
    data.append('id', id);
    return await loadJson (data, 'get_project_serials');
}

export async function delete_projects_permission(token, id_permission){
    const data = new FormData();
    data.append('token', token);
    data.append('id_permission', id_permission);
    return await loadJson (data, 'delete_projects_permission');
}

export async function insert_projects_permission(token, usr_name, email_id, project_id){
    const data = new FormData();
    data.append('token', token);
    data.append('usr_name', usr_name);
    data.append('email_id', email_id);
    data.append('project_id', project_id);
    return await loadJson (data, 'insert_projects_permission');
}

export async function get_project_updt_status(token, listid){
    const data = new FormData();
    data.append('token', token);
    data.append('listID', listid);
    return await loadJson (data, 'brevo/get_project_updt_status');
}

export async function loadStatusType(token){
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_status_types');
}

export async function getPackageVersion (token, packageName) {
    const data = new FormData();
    data.append('token', token);
    data.append('packageName', packageName);
    return await loadJson (data, 'get_versions_by_package');
}


export async function get_model_issues(token, reference){
    const data = new FormData();
    data.append('token', token);
    data.append('model_ref', reference);
    return await loadJson (data, 'get_model_issues');
}

export async function add_model(token, new_model){
    const data = new FormData();
    data.append('token', token);
    data.append('new_model', new_model);
    return await loadJson (data, 'add_model');
}


export async function update_destination (token, packageId, newDestination) {
    const data = new FormData();
    data.append('token', token);
    data.append('packageId', packageId);
    data.append('newDestination', newDestination);
    let result = await loadJson (data, 'update_destination');
    return result[0];
}
export async function get_devices_info(token, serial){
    console.log("serial a consultar: " +serial);
    const data = new FormData();
    data.append('token', token);
    data.append('serial', serial);
    return await loadJson (data, 'get_devices_info');
}


async function loadJson (data, url) {

    try {

   
        console.log(baseURL + url + ".php");
        const response = await fetch(baseURL + url + ".php", {
            method: 'POST',
            body: data
        });
        const resp = await response.text();
        console.log(url + " plain answer:" + resp);
        if(resp !== "INVALID"){
            try{
                const jsonResult = JSON.parse(resp);
                return jsonResult;
            }catch(err){
                return resp;
            }
            
        }else{
            return "INVALID";
        }
       
    } catch (error) {
        console.error('Error:' + error);
        return null;
    }
}



