import React, { useEffect, useRef, useState } from 'react';
import './Accounting.css';
import { card_list_csv, create_card_checkpoint, create_checkpoint, delete_stock_document, get_all_members, get_all_stocks, get_base_url, get_checkpoints, get_current_cards, get_member_stocks, load_movements, newStockTransaction, update_member } from '../../../utils/talent_services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Modal_proyectos from '../../menus/Modal_proyectos_brevo';
import Select from "react-select";


const Individual_stock = ({usr_id}) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [Holderslist, setHolderslist] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedOrigin, setselectedOrigin] = useState(0);
  const [selectedDestiny, setselectedDestiny] = useState(0);
  const [members, setMembers] = useState([]);
  const [newAmount, setnewAmount] = useState(0);
  const [TotalShares, setTotalShares] = useState(0);
  const [Nombre, setNombre] = useState('');
  const [file, setFile] = useState(null);
  const holder_id = searchParams.get("holder_id");

    useEffect(() => {
      console.log('holder_id:', holder_id , " user:", usr_id);
      if (holder_id !== null) {
      }
      
    }, [holder_id]); 


  const DeleteRef = (comprobante2delete) => {

    async function fetchDelete() {
        const membersList = await delete_stock_document(token, comprobante2delete);
        if (membersList==="INVALID"){
          navigate("/");
        }else{
          refresh_stocks_list(holder_id);
                  
       }
      }

      if (window.confirm("¿Está seguro de querer eliminar el movimiento " + comprobante2delete + " ?" )) {
        fetchDelete(comprobante2delete);
      }
      
  }

  const refresh_stocks_list = (member_id) => {
    async function fetchStocks(member_id) {
        const holdersListget = await get_member_stocks(token, member_id);
        if (holdersListget==="INVALID"){
          navigate("/");
        }else{

          let total_shares =0;
          const holdesLoad = holdersListget.map((element, index) => {
            total_shares +=Number(element.amount);
            setNombre (element.Nombre);
            return {id:element.id, filename:element.filename, OriginHolder:element.OriginHolder, comprobante: element.comprobante, date_transaction: element.date_transaction, comment:element.comment, holder: element.holder, Name: element.Nombre, amount: element.amount, index: index };
          });


          setTotalShares (total_shares);
          setHolderslist(holdesLoad);
                  
       }
      }
      fetchStocks(member_id);
  }

  

  
  useEffect(() => {
    if (holder_id != 0){
      refresh_stocks_list(holder_id);
    }else{

      refresh_stocks_list(usr_id);
    }
    
    console.log("get base URL:" + get_base_url());

  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  const formatCosto = (costo) => {
    if (!costo) return ""; // Maneja valores vacíos o null

    // Permitir solo números y un '-' inicial
    const numericValue = costo.replace(/[^\d-]/g, ""); 

    // Asegurar que solo haya un '-' y esté al inicio
    const isNegative = numericValue.startsWith("-");
    const cleanNumber = numericValue.replace(/-/g, ""); // Elimina cualquier otro '-'

    if (!cleanNumber) return ""; // Evita "NaN" si no hay números

    // Convertir a número y formatear
    const formattedNumber = parseInt(cleanNumber, 10).toLocaleString("es-ES");

    return isNegative ? `-${formattedNumber}` : formattedNumber;
};

  const newEntryModal = () => {

    setModalOpen(true);
  };

  return (
 
    <div className="containerChk">
      <Modal_proyectos isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <h3>Nuevo Movimiento de Stocks</h3>
       
     
        
      </Modal_proyectos>

        <div className='cards-list'>
        <h3>Total Acciones de "{Nombre}":  {formatCosto(String(TotalShares))}  </h3>
   
        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Comprobante</th>
              <th style={{ width: '15%', textAlign: 'right'  }}>Fecha</th>
              <th style={{ width: '15%', textAlign: 'right'  }}>Tercero</th>
              <th style={{ width: '15%', textAlign: 'right'  }}>Amount</th>
              <th style={{ width: '45%', textAlign: 'right'  }}>Comment</th>
              <th style={{ width: '5%', textAlign: 'right'  }}>Accion</th>
              
            </tr>
          </thead>
          <tbody>
            {Holderslist.map((member, index) => {

                    return (
                        <tr
                          key={index}
                        >
                        <td>{member.comprobante}</td>
                        <td style={{ textAlign: 'right' }}>{member.date_transaction}</td>
                        <td style={{ textAlign: 'right' }}>{member.OriginHolder}</td>
                        <td style={{ textAlign: 'right' }}>{formatCosto(String(member.amount))}</td>
                        <td style={{ textAlign: 'right' }}>{(member.comment )}
                          <a href={get_base_url() + member.filename} target='_blank'> Documento Soporte</a>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                        <button onClick={() => DeleteRef(member.comprobante)}>Eliminar</button>
                        </td>
                       
                        </tr>
                      )
          
              })}
          </tbody>
         
        </table>

        </div>
    </div>
      
   
  );
};

export default Individual_stock;
