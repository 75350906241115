import React, { useEffect, useRef, useState } from 'react';
import './Accounting.css';
import { card_list_csv, create_card_checkpoint, create_checkpoint, get_all_members, get_checkpoints, get_current_cards, load_movements, update_member } from '../../../utils/talent_services';
import { useNavigate } from 'react-router-dom';


const Accounting = ({usr_id}) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const  inputCSV = useRef(null);

  const [movements, setMovements] = useState([]);



  const parseCSV = () => {

    if(inputCSV.current.value) {
      const parsedMovements = parseData(inputCSV.current.value);
     setMovements(parsedMovements);
  }else{
    setMovements([]);
  }


  };

  
    const loadmovementsData = () => {

      async function upload_movements_data() {


          const checkpointlist = await load_movements(token,movements);
          if (checkpointlist==="INVALID"){
            navigate("/");
          }else{
            alert("Enviado con éxito");
            inputCSV.current.value = "";
         }
        }

        
    if (window.confirm("Se subirán " + movements.length 
      + "registros de gastos al sistema. Asegúrense que ningún movimiento se haya subido algo o podría quedar duplicado." 
    )) {
      upload_movements_data();
    }
          

    }
  

  const parseData = (data) => {
    const lines = data.trim().split('\n');
    return lines.map((line, index) => {
      const [fecha, id, monto,comment] = line.split('\t');
      return {
        trello_id: id,  // Asignar un valor fijo o extraído de algún lugar
        fecha: fecha,    // Asignar un valor fijo o extraído de algún lugar
        comment: comment,       // Asignar un valor fijo o extraído de algún lugar
        costo: parseInt(monto.replace('$', '').replace('.', '').replace(' ','').trim()),  // Convertir a número
        index: index,
      };
    });
  };


  const downloadHistoricCardList = () => {

     
    async function fetch_update_Dashboard() {
  
        const checkpointlist = await card_list_csv(token);
       
      }
      fetch_update_Dashboard();
    
  };
  

  
  useEffect(() => {
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  const formatCosto = (costo) => {

    return "$" + Number(costo).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0 });
  };


  return (
 
    <div className="containerChk">
       <div className='left-text'>
        <textarea
                              id="serial"
                              rows="15"
                              cols="50"
                              onChange={(e) => parseCSV(e.target.value)}
                              ref={inputCSV}
                            />

          <div >
            <button className='bt-function' onClick={() => loadmovementsData()}>Enviar datos</button>
          </div>

          <div >
          <button className='bt-function' onClick={downloadHistoricCardList}>Descargar Indice de Tarjetas</button>
        </div>
       </div>

        <div className='cards-list'>
        <h3> Members</h3>
        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Fecha</th>
              <th style={{ width: '30%' }}>Card_id</th>
              <th style={{ width: '20%' }}>Amount</th>
              <th style={{ width: '30%' }}>Comment</th>
              
            </tr>
          </thead>
          <tbody>
            {movements.map((member, index) => {

                    return (
                        <tr
                          key={index}
                        >
                        <td>{member.fecha}</td>
                        <td>{member.trello_id}</td>
                        <td>
                       {
                        
                          formatCosto(member.costo)  
                        }
                      </td>
                        <td>{member.comment}</td>
                       
                        </tr>
                      )
          
              })}
          </tbody>
        </table>
        </div>
    </div>
      
   
  );
};

export default Accounting;
